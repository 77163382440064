export const environment = {
  production: true,
  env: 'stage',
  baseUrl: `https://admin.stage.natodi.com`,
  apiEndpoint: `https://api.stage.natodi.com/api/v1`,
  widgetEndpoint: `https://book.stage.natodi.com`,
  auth0ClientId: `aQveNqYJuujT0SEdtiQwrUT3pkMcmpeM`,
  audience: `https://dev-8vff3rpvndwo8nkc.eu.auth0.com/api/v2/`,
  auth0Domain: `auth.stage.natodi.com`,
  amplitudeApiKey: `35b30d81e3e3d799f9ec846e92d9833c`,
  paddleClientApiToken: 'test_6d98825d76e187fce1fdbc75a2a',
  paddleInfo: {
    paddleProductIds: {
      free: 'pro_01jj4b06q9v1nc3bfz58dvjwj0',
      individual: 'pro_01jj4bas5mbenh80r7hrtmdnb5',
      team: 'pro_01jhwhjvqx77fvrmtja7cdh015',
    },
    monthItems: [
      { // Free
        quantity: 1,
        priceId: 'pri_01jj4b6pcfd3hfh5gnzw3aa2e4',
      },
      { // Individual
        quantity: 1,
        priceId: 'pri_01jj4bc2s252cgdm1dzqjexbm2',
      },
      { // Team
        quantity: 1,
        priceId: 'pri_01jhwhpj08zy5nvk110zwnjnmv',
      },
    ],
    yearItems: [
      { // Free
        quantity: 1,
        priceId: 'pri_01jj4b7gzacvya4r3sz3r32cde',
      },
      { // Individual
        quantity: 1,
        priceId: 'pri_01jj4beksjzzz7er2z051691zk',
      },
      { // Team
        quantity: 1,
        priceId: 'pri_01jhwhmsjsdgwwnzfez7km1k67',
      },
    ],
  },
  promoCodeUrl: 'https://natodi.notion.site/1b8642bea6f98038bc7ec431526c7ad9',
  infoCenterUrl: 'https://natodi.notion.site/198642bea6f981c09d84e760c601a7b9?v=198642bea6f981eab3e5000c00169e48',
};
